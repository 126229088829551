<template>
  <div>
    <p v-show="errorContratacion" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      {{ errorContratacion }}
    </p>

    <v-card :loading="loadginContratacion" v-if="!errorContratacion">
      <v-card-title>
        <v-img contain max-height="150" src="../../assets/support-services.png">
        </v-img>
      </v-card-title>

      <br />

      <h2 class="text-center">Contratación #{{ contratacion.numero }}</h2>

      <br />

      <v-divider></v-divider>

      <v-card-title>
        Datos de la contratación
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h3>Número:</h3>
            <p>{{ contratacion.numero }}</p>

            <h3>Fecha:</h3>
            <p>{{ new Date(contratacion.fecha).toLocaleDateString() }}</p>

            <h3>Clientes:</h3>
            <p>{{ contratacion.clientes.join(', ') }}</p>

            <h3>
              Observaciones:

              <EditarObservaciones
                :contratacion="contratacion"
                @updateContratacion="getContratacion($route.params.id)"
              ></EditarObservaciones>

              <v-btn
                icon
                color="red"
                v-bind="attrs"
                @click="eliminarObservaciones()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </h3>
            <p>
              {{ contratacion.observaciones }}
            </p>
          </v-col>

          <v-col>
            <h3>
              Estado:

              <v-btn
                icon
                color="orange"
                v-bind="attrs"
                @click="cambiarEstado()"
              >
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </h3>
            <p>
              <v-chip v-if="contratacion.estado" color="success" small>
                Pagada
              </v-chip>

              <v-chip v-else color="error" small>
                Pendiente
              </v-chip>
            </p>

            <h3>Precio total de la contratación:</h3>
            <p>{{ contratacion.precio }} €</p>

            <h3>Cantidad pagada:</h3>
            <p>{{ contratacion.cantidadPagada }} €</p>

            <h3>Última modificación:</h3>
            <p>
              {{ new Date(contratacion.ultimaModificacion).toLocaleString() }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <ServiciosContratados
        @updateContratacion="getContratacion($route.params.id)"
      ></ServiciosContratados>

      <v-divider></v-divider>

      <Recibos
        @updateContratacion="getContratacion($route.params.id)"
      ></Recibos>

      <v-divider></v-divider>

      <v-card-actions class="mt-4">
        <v-btn color="red" @click="eliminarContratacion(contratacion._id)">
          <v-icon>mdi-delete</v-icon>
          Eliminar contratacion
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import ServiciosContratados from '../ServiciosContratados/ServiciosContratados';
import Recibos from '../Recibos/Recibos';
import EditarObservaciones from './EditarObservaciones';

export default {
  data() {
    return {
      contratacion: {},
      errorContratacion: null,
      loadginContratacion: true,
    };
  },
  methods: {
    async getContratacion(id) {
      try {
        this.loadginContratacion = true;

        const res = await axios.get(
          process.env.VUE_APP_API_URL + '/contrataciones?id=' + id
        );

        this.contratacion = res.data;

        this.loadginContratacion = false;
      } catch (error) {
        this.loadginContratacion = false;

        this.errorContratacion = error.response.data;
      }
    },
    async eliminarContratacion(id) {
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + '/contrataciones/eliminar',
          {
            id,
          }
        );
        this.$router.push({ name: 'Contrataciones' });
      } catch (error) {
        alert('Error - No se ha podido eliminar el contratacion');
      }
    },
    async eliminarObservaciones() {
      try {
        this.contratacion.observaciones = '';

        await axios.post(
          process.env.VUE_APP_API_URL + '/contrataciones/editar',
          {
            id: this.contratacion._id,
            observaciones: this.contratacion.observaciones,
          }
        );
      } catch (error) {
        alert('Error - No se han podido eliminar las observaciones');
      }
    },
    async cambiarEstado() {
      try {
        this.contratacion.estado = !this.contratacion.estado;

        await axios.post(
          process.env.VUE_APP_API_URL + '/contrataciones/editar',
          {
            id: this.contratacion._id,
            estado: this.contratacion.estado,
          }
        );
      } catch (error) {
        alert('Error - No se han podido cambiar el estado de la contratacion');
      }
    },
  },
  components: {
    ServiciosContratados,
    Recibos,
    EditarObservaciones,
  },
  mounted() {
    this.getContratacion(this.$route.params.id);
  },
};
</script>
