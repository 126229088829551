<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        color="blue"
        class="ml-2"
        v-bind="attrs"
        v-on="on"
        @click="getServicios"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nuevo servicio contratado
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="nuevoServicioContratado.idServicio"
          :items="servicios"
          label="Servicio"
          clearable
          @input="getPrecios"
        ></v-autocomplete>

        <v-autocomplete
          v-model="nuevoServicioContratado.idPrecio"
          :items="precios"
          label="Opción y precio"
          clearable
        ></v-autocomplete>

        <v-text-field
          label="Duración"
          v-model="nuevoServicioContratado.duracion"
          :hint="
            `Cantidad de sesiones, talleres, horas, días, meses, trimestres, etc.`
          "
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          v-model="nuevoServicioContratado.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fecha: false,
      servicios: [],
      precios: [],
      nuevoServicioContratado: {
        idServicio: '',
        idPrecio: '',
        duracion: '',
        observaciones: '',
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.nuevoServicioContratado.idContratacion = this.$route.params.id;
        this.loading = true;

        await axios.post(
          process.env.VUE_APP_API_URL + '/servicioscontratados/nuevo',
          this.nuevoServicioContratado
        );

        this.loading = false;

        this.toggleDialog = false;

        this.$emit('edit');
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
    async getServicios() {
      try {
        let servicios = await axios.get(
          process.env.VUE_APP_API_URL + '/servicios'
        );
        servicios = servicios.data;

        for (let i = 0; i < servicios.length; i++) {
          this.servicios.push({
            text: servicios[i].nombre,
            value: servicios[i]._id,
          });
        }
      } catch (error) {
        alert('Error - No se pueden obtener los servicios');

        this.toggleDialog = false;
      }
    },
    async getPrecios() {
      this.precios = [];
      try {
        let precios = await axios.get(
          process.env.VUE_APP_API_URL +
            '/precios?idServicio=' +
            this.nuevoServicioContratado.idServicio
        );
        precios = precios.data;

        for (let i = 0; i < precios.length; i++) {
          this.precios.push({
            text: `${precios[i].opcion} - ${precios[i].precio} €/${precios[i].cobro}`,
            value: precios[i]._id,
          });
        }
      } catch (error) {
        alert('Error - No se pueden obtener los precios');

        this.toggleDialog = false;
      }
    },
  },
};
</script>
