<template>
  <div>
    <v-card-title>
      Recibos

      <NuevoRecibo
        :idContratacion="$route.params.id"
        @edit="
          getTableData($route.params.id);
          $emit('updateContratacion');
        "
      ></NuevoRecibo>
    </v-card-title>
    <v-card-text>
      <p v-show="errorTableData" class="text-center">
        <v-icon>mdi-alert-circle</v-icon>
        Error obteniendo los datos
      </p>

      <v-data-table
        v-if="!errorTableData"
        :headers="tableHeaders"
        :items="tableData"
        :loading="loadingTableData"
        loading-text="Cargando..."
        @click:row="rowClick"
      >
        <template v-slot:item.subtotal="{ item }">
          {{ item.subtotal }} €
        </template>

        <template v-slot:item.descuento="{ item }">
          <p v-if="item.descuento">{{ item.descuento }} €</p>
        </template>

        <template v-slot:item.total="{ item }"> {{ item.total }} € </template>

        <template v-slot:item.fecha="{ item }">
          {{ new Date(item.fecha).toLocaleDateString() }}
        </template>

        <template v-slot:item.pagado="{ item }">
          <v-chip v-if="item.pagado" color="success" small>
            Pagado
          </v-chip>

          <v-chip v-else color="error" small>
            Pendiente
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoRecibo from './NuevoRecibo';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: null,
      loadingTableData: true,
      tableHeaders: [
        {
          text: 'Número',
          value: 'numero',
        },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Forma de pago', value: 'formaPago', sortable: false },
        { text: 'Subtotal', value: 'subtotal', sortable: false },
        { text: 'Descuento', value: 'descuento', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Observaciones', value: 'observaciones', sortable: false },
        { text: 'Estado', value: 'pagado', sortable: false },
      ],
    };
  },
  methods: {
    async getTableData(idContratacion) {
      try {
        this.loadingTableData = true;
        const res = await axios.get(
          process.env.VUE_APP_API_URL +
            '/recibos?idContratacion=' +
            idContratacion
        );
        this.tableData = res.data;
        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;
        this.errorTableData = error.response.data;
      }
    },
    async rowClick(row) {
      this.$router.push({ name: 'Recibo', params: { id: row._id } });
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(process.env.VUE_APP_API_URL + '/recibos/eliminar', {
          id,
        });
        this.getTableData(this.$route.params.id);
        this.$emit('updateContratacion');
      } catch (error) {
        alert('Error - No se ha podido eliminar');
      }
    },
  },
  components: {
    NuevoRecibo,
  },
  mounted() {
    this.getTableData(this.$route.params.id);
  },
};
</script>
