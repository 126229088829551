<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="orange" v-bind="attrs" v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar las observaciones
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Observaciones"
          v-model="contratacion.observaciones"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('updateContratacion');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarObservaciones',
  props: ['contratacion'],
  data() {
    return {
      loading: false,
      toggleDialog: false,
    };
  },
  methods: {
    async editar() {
      try {
        this.loading = true;

        await axios.post(
          process.env.VUE_APP_API_URL + '/contrataciones/editar',
          {
            id: this.contratacion._id,
            observaciones: this.contratacion.observaciones,
          }
        );

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
