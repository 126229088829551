<template>
  <v-dialog persistent v-model="toggleDialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        color="blue"
        class="ml-2"
        v-bind="attrs"
        v-on="on"
        @click="
          getContratacion();
          getServiciosContratados();
        "
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        <h2>Nuevo recibo</h2>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-menu
          ref="menu"
          v-model="fecha"
          :close-on-content-click="false"
          :return-value.sync="nuevoRecibo.fecha"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="nuevoRecibo.fecha"
              label="Fecha del recibo"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="nuevoRecibo.fecha" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fecha = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="nuevoRecibo.fecha = ''">
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(nuevoRecibo.fecha)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-card-text>

      <v-card-text>
        <v-select
          v-model="nuevoRecibo.clientes"
          :items="contratacion.clientes"
          label="Clientes a cobrar"
          hint="Elige los clientes de esta contratación a los que les quieres cobrar"
          persistent-hint
          clearable
          multiple
        ></v-select>
      </v-card-text>

      <v-card-text>
        <v-select
          v-model="nuevoRecibo.idServiciosContratadosAcobrar"
          :items="nombresDeLosServiciosContratados"
          label="Servicios a cobrar"
          hint="Elige los servicios que le quieres cobrar al/los cliente/s"
          persistent-hint
          clearable
          multiple
        ></v-select>
      </v-card-text>

      <v-card-text>
        <h3 v-show="nuevoRecibo.idServiciosContratadosAcobrar.length > 0">
          Cantidades por servicio
        </h3>
        <div
          v-for="(idServicioAcobrar,
          index) in nuevoRecibo.idServiciosContratadosAcobrar"
          :key="idServicioAcobrar"
        >
          <v-select
            :label="
              `${
                serviciosContratados[
                  idsDeLosServiciosContratados.indexOf(idServicioAcobrar)
                ].nombre
              } - ${
                serviciosContratados[
                  idsDeLosServiciosContratados.indexOf(idServicioAcobrar)
                ].precioPorCliente
              } €/cliente/${
                serviciosContratados[
                  idsDeLosServiciosContratados.indexOf(idServicioAcobrar)
                ].cobro
              }`
            "
            :items="duraciones[idServicioAcobrar]"
            v-model="nuevoRecibo.duraciones[index]"
          ></v-select>

          <p>
            Total servicio:
            {{
              serviciosContratados[
                idsDeLosServiciosContratados.indexOf(idServicioAcobrar)
              ].precioPorCliente *
                nuevoRecibo.duraciones[index] *
                nuevoRecibo.clientes.length
            }}
            €
          </p>
        </div>

        <h2>Subtotal recibo: {{ subtotal }} €</h2>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-text-field
          label="Descuento"
          v-model="nuevoRecibo.descuento"
        ></v-text-field>

        <h2>Total recibo: {{ subtotal - nuevoRecibo.descuento }} €</h2>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-checkbox label="Pagado" v-model="nuevoRecibo.pagado"></v-checkbox>

        <v-select
          v-model="nuevoRecibo.formaPago"
          :items="formasDePago"
          label="Forma de pago"
          persistent-hint
          clearable
        ></v-select>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-text-field
          label="Referencia del pago"
          v-model="nuevoRecibo.refPago"
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          v-model="nuevoRecibo.observaciones"
        ></v-text-field>

        <v-checkbox
          v-model="nuevoRecibo.mostrarObservaciones"
          label="Mostrar las observaciones en el recibo"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fecha: false,
      contratacion: {},
      serviciosContratados: [],
      idsDeLosServiciosContratados: [],
      duraciones: {},
      nombresDeLosServiciosContratados: [],
      formasDePago: ['Efectivo', 'Tarjeta', 'Transferencia', 'Bizum'],
      nuevoRecibo: {
        fecha: '',
        clientes: [],
        idServiciosContratadosAcobrar: [],
        duraciones: [],
        descuento: '',
        observaciones: '',
        formaPago: '',
        refPago: '',
        pagado: false,
        mostrarObservaciones: false,
      },
    };
  },
  computed: {
    subtotal: function() {
      let subtotal = 0;
      const idServiciosContratadosAcobrar = this.nuevoRecibo
        .idServiciosContratadosAcobrar;

      for (let i = 0; i < idServiciosContratadosAcobrar.length; i++) {
        subtotal =
          subtotal +
          this.serviciosContratados[
            this.idsDeLosServiciosContratados.indexOf(
              idServiciosContratadosAcobrar[i]
            )
          ].precioPorCliente *
            this.nuevoRecibo.duraciones[i] *
            this.nuevoRecibo.clientes.length;
      }

      return subtotal;
    },
  },
  methods: {
    async crear() {
      try {
        this.nuevoRecibo.idContratacion = this.$route.params.id;
        this.loading = true;

        await axios.post(
          process.env.VUE_APP_API_URL + '/recibos/nuevo',
          this.nuevoRecibo
        );

        this.loading = false;

        this.toggleDialog = false;

        this.$emit('edit');
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
    async getContratacion() {
      try {
        const contratacion = await axios.get(
          process.env.VUE_APP_API_URL +
            '/contrataciones?id=' +
            this.$route.params.id
        );
        this.contratacion = contratacion.data;
      } catch (error) {
        alert('Error - No se pueden obtener la contratacion');

        this.toggleDialog = false;
      }
    },
    async getServiciosContratados() {
      try {
        const serviciosContratados = await axios.get(
          process.env.VUE_APP_API_URL +
            '/serviciosContratados?idContratacion=' +
            this.$route.params.id
        );
        this.serviciosContratados = serviciosContratados.data;

        this.nombresDeLosServiciosContratados = [];
        this.idsDeLosServiciosContratados = [];

        for (let i = 0; i < this.serviciosContratados.length; i++) {
          this.nombresDeLosServiciosContratados.push({
            text: this.serviciosContratados[i].nombre,
            value: this.serviciosContratados[i]._id,
          });

          this.idsDeLosServiciosContratados.push(
            this.serviciosContratados[i]._id
          );

          this.duraciones[this.serviciosContratados[i]._id] = [];

          for (let h = 0; h < this.serviciosContratados[i].duracion; h++) {
            this.duraciones[this.serviciosContratados[i]._id].push({
              text: `${h + 1} ${this.serviciosContratados[i].cobro}(s/es)`,
              value: h + 1,
            });
          }
        }
      } catch (error) {
        alert('Error - No se pueden obtener los precios');

        this.toggleDialog = false;
      }
    },
  },
};
</script>
