<template>
  <div>
    <v-card-title>
      Servicios contratados

      <NuevoServicioContratado
        :idContratacion="$route.params.id"
        @edit="
          getTableData($route.params.id);
          $emit('updateContratacion');
        "
      ></NuevoServicioContratado>
    </v-card-title>
    <v-card-text>
      <p v-show="errorTableData" class="text-center">
        <v-icon>mdi-alert-circle</v-icon>
        Error obteniendo los datos
      </p>

      <v-data-table
        v-if="!errorTableData"
        :headers="tableHeaders"
        :items="tableData"
        :loading="loadingTableData"
        loading-text="Cargando..."
      >
        <template v-slot:item.precioPorCliente="{ item }">
          {{ item.precioPorCliente }} €
        </template>
        <template v-slot:item.precioTotal="{ item }">
          {{ item.precioTotal }} € </template
        ><template v-slot:item.duracion="{ item }">
          {{ item.duracion }} {{ item.cobro }}(s/es)
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon color="red" @click="eliminarRow(item._id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoServicioContratado from './NuevoServicioContratado';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: null,
      loadingTableData: true,
      tableHeaders: [
        {
          text: 'Nombre',
          value: 'nombre',
        },
        { text: 'Opción', value: 'opcion', sortable: false },
        {
          text: 'Precio por cliente',
          value: 'precioPorCliente',
          sortable: false,
        },
        { text: 'Duración', value: 'duracion', sortable: false },
        {
          text: 'Precio total del servicio',
          value: 'precioTotal',
          sortable: false,
        },
        { text: 'Observaciones', value: 'observaciones', sortable: false },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async getTableData(idContratacion) {
      try {
        this.loadingTableData = true;
        const res = await axios.get(
          process.env.VUE_APP_API_URL +
            '/servicioscontratados?idContratacion=' +
            idContratacion
        );
        this.tableData = res.data;
        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;
        this.errorTableData = error.response.data;
      }
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(
          process.env.VUE_APP_API_URL + '/servicioscontratados/eliminar',
          {
            id,
          }
        );
        this.$emit('updateContratacion');
        this.getTableData(this.$route.params.id);
      } catch (error) {
        alert('Error - No se ha podido eliminar');
      }
    },
  },
  components: {
    NuevoServicioContratado,
  },
  mounted() {
    this.getTableData(this.$route.params.id);
  },
};
</script>
